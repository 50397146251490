import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const ItemsGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`;

export default function SingleItemsPage ({ data: { items } }) {
  return (
    <ItemsGrid>
      {/* <Img fluid={items.image.asset.fluid} /> */}
      <div>
        <h2 className="mark">{items.name}</h2>
        <ul>
          {items.category.map((category) => (
            <li key={category.id}>{category.name}</li>
          ))}
        </ul>
      </div>
    </ItemsGrid>
  );
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query($slug: String!) {
    items: sanityItems(slug: { current: { eq: $slug } }) {
      name
      id
      category {
        name
        id
      }
    }
  }
`;